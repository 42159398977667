import clsx from 'clsx';
import React from 'react';

interface Props {
  thisColor: 'primary' | 'secondary' | 'neutralLighter' | 'neutralDarker';
  nextColor: 'primary' | 'secondary' | 'neutralLighter' | 'neutralDarker';
  className?: string;
}

const WaveBorderSection: React.FC<Props> = ({ thisColor, nextColor, className, children }) => {
  const thisBackgroundColor = `bg-${thisColor}`;
  const nextBackgroundColor = `bg-${nextColor}`;

  return (
    <section className="w-full">
      <div className={clsx('w-full', nextBackgroundColor)}>
        <div className={clsx('p-8 pt-0 w-full rounded-bl-lg', thisBackgroundColor, className)}>
          <div className="m-auto max-w-2xl lg:max-w-5xl">{children}</div>
        </div>
      </div>
      <div className={clsx('w-full h-8', thisBackgroundColor)}>
        <div className={clsx('w-full h-full rounded-tr-lg', nextBackgroundColor)}></div>
      </div>
    </section>
  );
};

export default WaveBorderSection;
