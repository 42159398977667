import clsx from 'clsx';
import React from 'react';

interface Props {
  label: string;
  href?: string;
  hrefPrefix?: string;
  className?: string;
}

const Link: React.FC<Props> = ({ label, href, hrefPrefix, className }) => {
  let link;
  if (href) link = href;
  else if (hrefPrefix) {
    if (hrefPrefix === 'tel') link = `${hrefPrefix}://0041-${label.replace(/^0+/, '').replace(/ /gi, '-')}`;
    else link = `${hrefPrefix}:${label}`;
  } else link = '#';

  return (
    <a href={link} className={clsx('text-primary', className)}>
      {label}
    </a>
  );
};

export default Link;
