import React from 'react';
import Link from '../../../components/text/Link';

interface Props {
  memberId: 'ck' | 'ab' | 'dg' | 'ma' | 'gl' | 'ms';
  title?: string;
  name: string;
  subtitle: string;
  cvLink?: string;
}

const TeamMemberLarge: React.FC<Props> = ({ memberId, title, name, subtitle, cvLink }) => {
  return (
    <div className="flex flex-1 flex-row space-x-2">
      <div className="w-36 h-36 rounded-full overflow-hidden lg:w-48 lg:h-48">
        <img src={`./images/praxis-${memberId}-portrait.jpg`} />
      </div>
      <div className="flex flex-col justify-center space-y-2">
        <div className="flex-start space-y-0">
          {title && <p>{title}</p>}
          <h5>{name}</h5>
        </div>
        <p>{subtitle}</p>
        {cvLink && <Link href={cvLink} label="Lebenslauf" />}
      </div>
    </div>
  );
};

export default TeamMemberLarge;
