import React from 'react';
import clsx from 'clsx';
import { IoMenu } from 'react-icons/io5';

interface Props {
  onClick: () => void;
  className?: string;
}

const MobileNavButton: React.FC<Props> = ({ onClick, className }) => {
  return (
    <div
      className={clsx(
        'fixed z-10 right-6 top-6 flex items-center justify-center w-16 h-16 bg-neutralLighter rounded-full shadow-lg cursor-pointer',
        className,
      )}
      onClick={onClick}
    >
      <IoMenu className="w-10 h-10 text-primary" />
    </div>
  );
};

export default MobileNavButton;
