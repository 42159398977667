import clsx from 'clsx';
import React from 'react';
import { useHistory } from 'react-router-dom';
import WaveBorderSection from '../../components/container/WaveBorderSection';

interface Props {
  pageName?: string;
  subPageName?: string;
  large?: boolean;
}

const WaveHeader: React.FC<Props> = ({ pageName, subPageName, large = false, children }) => {
  // const [isTitleAnimationFinished, setIsTitleAnimationFinished] = useState(false);
  const history = useHistory();

  return (
    <WaveBorderSection thisColor="primary" nextColor="neutralLighter">
      <header className={clsx('pt-12 transition-all', { 'h-48': large }, { 'h-32': !large })}>
        {large ? (
          children
        ) : (
          <>
            <h1 className="mb-2 text-sm cursor-pointer" onClick={() => history.push('/')}>
              Gynteam St Gallen
            </h1>
            <h2 className="text-2xl">
              <span className={clsx({ 'text-primaryLighter': subPageName, 'text-neutralLighter': !subPageName })}>
                {pageName}
              </span>
              {subPageName && <span> / {subPageName}</span>}
            </h2>
          </>
        )}
      </header>
    </WaveBorderSection>
  );
};

export default WaveHeader;
