import React from 'react';
interface Props {
  memberId: 'ck' | 'ab' | 'dg' | 'ma' | 'gl' | 'ms';
  name: string;
}

const TeamMemberSmall: React.FC<Props> = ({ memberId, name }) => {
  return (
    <div className="flex flex-col items-start space-y-2">
      <div className="w-36 h-36 rounded-full overflow-hidden lg:w-48 lg:h-48">
        <img src={`./images/praxis-${memberId}-portrait.jpg`} />
      </div>
      <p>{name}</p>
    </div>
  );
};

export default TeamMemberSmall;
