import React from 'react';
import WaveBorderSection from '../../components/container/WaveBorderSection';
import WaveFooter from '../../components/default/WaveFooter';
import WaveHeader from '../../components/default/WaveHeader';

const DirectionsScreen: React.FC = () => {
  return (
    <div>
      <WaveHeader pageName="Anfahrt" />
      <WaveBorderSection thisColor="neutralLighter" nextColor="neutralDarker" className="pb-24 space-y-20">
        <img className="mb-4 rounded" src="./images/hero-directions.jpg" />
        <div className="grid gap-8 grid-cols-1 mb-12 lg:grid-cols-2 lg:mb-24">
          {/* Map Container */}
          <div className="relative z-0 mt-6">
            {/* Map Image */}
            <div
              className="aspect-w-3 aspect-h-2 bg-neutralDarker rounded"
              style={{ backgroundImage: 'url(./images/praxis-map.jpg)', backgroundSize: 'cover' }}
            ></div>
            {/* Map Button */}
            <a
              href="maps:address=St.Leonhardstrasse 20, St.Gallen, Schweiz"
              className="absolute z-0 -bottom-10 left-0 right-0 flex items-center justify-center mx-auto w-3/5 h-20 text-neutralLighter bg-primary border-4 border-neutralLighter rounded cursor-pointer"
            >
              In Maps öffnen
            </a>
          </div>
          <div className="mt-6 space-y-6">
            <h5>Unsere Adresse</h5>
            <p>
              Praxis
              <br />
              Dr. med. Christiane Kluckert-Tomm
              <br />
              Dr. med. Andrea Biener
              <br />
              St. Leonhardstr. 20
              <br />
              9000 St. Gallen
            </p>
            <p>
              Die Praxis befindet sich in der Nähe des Hauptbahnhofs St.Gallen direkt gegenüber der St.Galler
              Kantonalbank.
            </p>
          </div>
        </div>
        <div className="flex flex-row mt-4">
          <div
            className="aspect-w-10 aspect-h-6 lg:aspect-h-3 w-2/5 bg-neutralLighter rounded lg:w-1/5"
            style={{ backgroundImage: 'url(./images/location_picture.jpg)', backgroundSize: 'cover' }}
          ></div>
          <div className="pl-4 w-3/5">
            <h5 className="m-0 mb-4 p-0">Anfahrt mit ÖV oder Auto</h5>
            <p>
              Mit dem öffentlichen Verkehr erreichen Sie uns mit den Buslinien 3 und 5 an der Haltestelle Broderbrunnen
              oder mit allen anderen Linien an der Haltestelle St.Gallen Bahnhof.
              <br />
              <a
                href="http://www.stadt.sg.ch/home/mobilitaet-verkehr/bus-bahn/fahrplan.html"
                target="_blank"
                rel="noreferrer"
              >
                Zum Fahrplan
              </a>
              <br />
              <br />
              Die Parkgaragen <a href="http://www.pls-sg.ch/parking/manor/">Manor</a>,{' '}
              <a href="http://www.pls-sg.ch/parking/neumarkt/">Neumarkt</a>,{' '}
              <a href="http://www.pls-sg.ch/parking/rathaus/">Rathaus</a> und{' '}
              <a href="http://www.pls-sg.ch/parking/bahnhof/">Bahnhof</a> befinden sich in unmittelbarer Nähe.
            </p>
          </div>
        </div>
      </WaveBorderSection>
      <WaveFooter />
    </div>
  );
};

export default DirectionsScreen;
