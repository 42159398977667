import React from 'react';
import { NavLink } from 'react-router-dom';

interface NavButtonProps {
  label: string;
  path: string;
}

const NavButton: React.FC<NavButtonProps> = ({ label, path }) => {
  return (
    <li>
      <NavLink
        exact
        className="group flex items-center justify-center p-2 w-16 text-neutralLighter text-lg font-thin cursor-pointer transform duration-100"
        activeClassName="border-neutralLighter border-b-2 font-semibold"
        to={path}
      >
        <span className="transform group-hover:-translate-y-1 duration-100">{label}</span>
      </NavLink>
    </li>
  );
};

export default NavButton;
