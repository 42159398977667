import React from 'react';
import clsx from 'clsx';

interface Props {
  label: string;
  isSelected: boolean;
  onClick: () => void;
}

const NavItem: React.FC<Props> = ({ label, isSelected, onClick }) => {
  return (
    <div
      className={clsx('p-4 w-3/4 text-center rounded-sm cursor-pointer', {
        'text-white bg-primary': isSelected,
        'text-primary bg-neutralLighter': !isSelected,
      })}
      onClick={onClick}
    >
      {label}
    </div>
  );
};

export default NavItem;
