import React from 'react';
import { useHistory } from 'react-router-dom';
import ContentSection from '../../components/container/ContentSection';
import WaveBorderSection from '../../components/container/WaveBorderSection';
import Link from '../../components/text/Link';

const WaveFooter: React.FC = () => {
  // const [isTitleAnimationFinished, setIsTitleAnimationFinished] = useState(false);
  const history = useHistory();

  return (
    <WaveBorderSection thisColor={'neutralDarker'} nextColor={'neutralDarker'}>
      <ContentSection title="Adresse" variant="onDark">
        <p>
          Praxis
          <br />
          Dr. med. Christiane Kluckert-Tomm
          <br />
          Dr. med. Andrea Biener
          <br />
          St. Leonhardstr. 20
          <br />
          9000 St. Gallen
        </p>
        <Link href="/impressum" label="Impressum" />
      </ContentSection>
      <ContentSection title="Anfahrt" variant="onDark">
        <div
          onClick={() => history.push('/anfahrt')}
          className="w-72 h-48 rounded cursor-pointer"
          style={{ backgroundImage: 'url(./images/praxis-map.jpg)', backgroundSize: 'cover' }}
        ></div>
      </ContentSection>
    </WaveBorderSection>
  );
};

export default WaveFooter;
