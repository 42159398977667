import React, { useRef, useState, useEffect } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import HomeScreen from './views/screens/Home/Home';
import '@fontsource/heebo';
import TeamScreen from './views/screens/Team/Team';
import MobileNavOverlay from './views/components/navigation/MobileNavOverlay';
import MobileNavButton from './views/components/navigation/MobileNavButton';
import DirectionsScreen from './views/screens/Directions/Directions';
import NavBar from './views/components/navigation/NavBar';
import Impressum from './views/screens/Impressum/Impressum';
import NavItem from './types/NavItem';

const navItems: NavItem[] = [
  { id: 0, label: 'Home', path: '/' },
  { id: 1, label: 'Team', path: '/team' },
  { id: 2, label: 'Anfahrt', path: '/anfahrt' },
];

/** The App's root component */
const App: React.FC = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const pageId = useRef(0);
  const history = useHistory();

  useEffect(() => {
    const currentItem = navItems.find((i) => i.path === history.location.pathname);
    if (currentItem) pageId.current = currentItem?.id;
  }, [history.location]);

  const onShowMobileNav = () => {
    setShowOverlay(true);
  };

  const onHideMobileNav = () => {
    setShowOverlay(false);
  };

  const navigateTo = (route: string) => {
    setShowOverlay(false);
    history.push(route);
  };

  return (
    <div>
      {showOverlay && (
        <MobileNavOverlay onClickBackground={onHideMobileNav} navigateTo={navigateTo} currentPageId={pageId.current} />
      )}
      {!showOverlay && <MobileNavButton onClick={onShowMobileNav} className={'md:hidden'} />}
      <NavBar navItems={navItems} className={'hidden md:flex'} />
      <Switch>
        <Route path="/team" component={TeamScreen} />
        <Route path="/anfahrt" component={DirectionsScreen} />
        <Route path="/impressum" component={Impressum} />
        <Route path="/" component={HomeScreen} />
        {/* Redirect all other to landing */}
        <Redirect from="*" to="/" />
      </Switch>
      {/* <CookieConsent
        location="bottom"
        buttonText="Okay"
        style={{ background: '#2B373B' }}
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
      >
        We are using cookies to enhance your user experience. By using this website, you agree to our terms and
        conditions. More info can be found in our <a href="/privacypolicy">privacy policy</a>.
      </CookieConsent> */}
    </div>
  );
};

export default App;
