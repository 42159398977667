import React from 'react';
import WaveBorderSection from '../../components/container/WaveBorderSection';
import WaveFooter from '../../components/default/WaveFooter';
import WaveHeader from '../../components/default/WaveHeader';
import TeamMemberLarge from './components/TeamMemberLarge';
import TeamMemberSmall from './components/TeamMemberSmall';

const TeamScreen: React.FC = () => {
  return (
    <div>
      <WaveHeader pageName="Team" />
      <WaveBorderSection thisColor="neutralLighter" nextColor="neutralDarker" className="pb-24 space-y-20">
        <div className="flex flex-col space-y-6 lg:flex-row">
          <TeamMemberLarge
            memberId="ck"
            title="Dr. med."
            name="Christiane Kluckert-Tomm"
            subtitle="Fachärztin Gynäkologie und Geburtshilfe"
            cvLink="#"
          />
          <TeamMemberLarge
            memberId="ab"
            title="Dr. med."
            name="Andrea Biener"
            subtitle="Fachärztin Gynäkologie und Geburtshilfe"
            cvLink="#"
          />
        </div>
        <div className="flex flex-col lg:flex-row">
          <div className="w-full space-y-6 lg:w-3/4">
            <h5>Medizinische Praxisassistentinnen</h5>
            <div className="grid grid-cols-3">
              <TeamMemberSmall memberId="dg" name="Daniela Granwehr" />
              <TeamMemberSmall memberId="ma" name="Monika Allenspach" />
              <TeamMemberSmall memberId="gl" name="Gabi Luchsinger" />
            </div>
          </div>
          <div className="w-full space-y-6 lg:w-1/4">
            <h5>Buchhaltung</h5>
            <div className="flex flex-row justify-between">
              <TeamMemberSmall memberId="ms" name="Monika Strittmatter" />
            </div>
          </div>
        </div>
      </WaveBorderSection>
      <WaveFooter />
    </div>
  );
};

export default TeamScreen;
