import clsx from 'clsx';
import React from 'react';

interface Props {
  variant?: 'onBright' | 'onDark';
  title: string;
  className?: string;
}

const ContentSection: React.FC<Props> = ({ variant = 'onBright', title, className, children }) => {
  let textColor;
  switch (variant) {
    case 'onBright':
      textColor = 'text-black';
      break;
    case 'onDark':
      textColor = 'text-white';
      break;
    default:
      textColor = 'text-black';
      break;
  }

  return (
    <section className={clsx('mb-14 w-full', textColor, className)}>
      <h4 className="mb-4">{title}</h4>
      {children}
    </section>
  );
};

export default ContentSection;
