import React from 'react';
import NavItem from './NavItem';

const navItems = [
  { id: 0, label: 'Home', path: '/' },
  { id: 1, label: 'Team', path: '/team' },
  { id: 2, label: 'Anfahrt', path: '/anfahrt' },
];

interface Props {
  onClickBackground: () => void;
  navigateTo: (route: string) => void;
  currentPageId: number;
}

const MobileNavOverlay: React.FC<Props> = ({ onClickBackground, navigateTo, currentPageId }) => {
  return (
    <div className="fixed z-10 bottom-0 left-0 right-0 top-0 flex items-center justify-center">
      <div
        onClick={onClickBackground}
        className="absolute z-10 bottom-0 left-0 right-0 top-0 bg-black opacity-70"
      ></div>
      <nav className="z-20 flex flex-col items-center w-full h-32 space-y-4">
        {navItems.map((i) => (
          <NavItem key={i.id} label={i.label} isSelected={currentPageId === i.id} onClick={() => navigateTo(i.path)} />
        ))}
      </nav>
    </div>
  );
};

export default MobileNavOverlay;
