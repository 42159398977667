import React from 'react';
import ContentSection from '../../components/container/ContentSection';
import WaveBorderSection from '../../components/container/WaveBorderSection';
import Link from '../../components/text/Link';
import { IoMail, IoCall, IoPrint } from 'react-icons/io5';
import WaveHeader from '../../components/default/WaveHeader';
import WaveFooter from '../../components/default/WaveFooter';
import CustomImage from '../../components/image/CustomImage';

const HomeScreen: React.FC = () => {
  // const navigateToSubPage = () => {
  //   history.push('/team');
  // };

  return (
    <div>
      <WaveHeader large>
        <h1 className="mb-">Gynteam St Gallen</h1>
        <div className="mb-6">
          <h2>Dr. med. Christiane Kluckert-Tomm</h2>
          <h2>Dr. med. Andrea Biener</h2>
        </div>
        <h3>{'Praxis für Gynäkologie und Geburtshilfe'}</h3>
      </WaveHeader>
      <WaveBorderSection thisColor="neutralLighter" nextColor="neutralDarker">
        <div className="flex flex-col mb-8 lg:flex-row-reverse">
          {/* <img src="./images/title-doctors.png" /> */}
          <CustomImage
            srcList="images/title-doctors.jpg"
            fillType="contain"
            className="flex-1 mb-4 rounded overflow-hidden"
          />
          <div className="flex-1 lg:mt-8">
            <h2 className="mb-4 text-center text-black text-2xl font-thin">Herzlich Willkommen!</h2>
            <p>Unsere Besonderheit ist die kompetente Beratung und Begleitung in jedem Lebensalter:</p>
            <ul className="my-4 px-8 text-lg space-y-4">
              <li className="text-secondary">Kinder- und Jugendzeit</li>
              <li className="text-center text-secondary">Familienplanung und Schwangerschaft</li>
              <li className="text-right text-secondary">Menopause und Senium</li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col space-x-6 lg:flex-row">
          <ContentSection title="Öffnungszeiten" variant="onBright">
            <div className="space-y-4">
              <div className="flex flex-row w-full">
                <div className="w-20 h-20 rounded-full overflow-hidden">
                  <img src="./images/praxis-ck-portrait.jpg" />
                </div>
                <div className="flex flex-col justify-center ml-4">
                  <p className="text-secondary">Montag, Dienstag, Mittwoch</p>
                  <p className="text-lg">07:30 - 11:45 / 13:15 - 17:00 Uhr</p>
                </div>
              </div>
              <div className="flex flex-row w-full">
                <div className="w-20 h-20 rounded-full overflow-hidden">
                  <img src="./images/praxis-ab-portrait.jpg" />
                </div>
                <div className="flex flex-col justify-center ml-4">
                  <p className="text-secondary">Donnerstag, Freitag</p>
                  <p className="text-lg">08:00 - 11:45 / 13:15 - 17:00 Uhr</p>
                </div>
              </div>
            </div>
            {/* Sonderöffnungszeiten */}
            {/* <div className="mt-8 p-6 bg-attention rounded">
              <p className="font-bold">
                Bitte beachten Sie die geänderten Öffnungszeiten zwischen 04.04. - 18.04.2022:
              </p>
              <div className="flex flex-col my-4 space-y-4">
                <div className="align-center flex flex-row justify-between">
                  <p>Montag (04.04 / 11.04.)</p>
                  <p>08:00 - 14 : 00</p>
                </div>
                <div className="align-center flex flex-row justify-between">
                  <p>Dienstag (05.04. / 12.04.)</p>
                  <div className="flex flex-col">
                    <p>08:00 - 12 : 00</p>
                    <p>13:00 - 17 : 00</p>
                  </div>
                </div>
                <div className="align-center flex flex-row justify-between">
                  <p>Mittwoch (06.04. / 13.04.)</p>
                  <p>geschlossen</p>
                </div>
                <div className="align-center flex flex-row justify-between">
                  <p>Donnerstag (07.04 / 14.04.)</p>
                  <div className="flex flex-col">
                    <p>08:00 - 12 : 00</p>
                    <p>13:00 - 17 : 00</p>
                  </div>
                </div>
                <div className="align-center flex flex-row justify-between">
                  <p>Freitag (08.04.)</p>
                  <div className="flex flex-col">
                    <p>08:00 - 12 : 00</p>
                    <p>13:00 - 16 : 00</p>
                  </div>
                </div>
              </div>
              <p className="my-4 font-bold">
                Am Freitag, 15.04.2022 und am Montag, 18.04.2022 bleibt die Praxis geschlossen.
              </p>
              <p>Ab Dienstag, 19.04.2022 sind wir ab 08:00 Uhr gerne wieder wie gewohnt für Sie da.</p>
            </div> */}
          </ContentSection>

          <ContentSection title="Kontakt" variant="onBright">
            <div className="space-y-4">
              <div className="flex flex-row justify-between w-full">
                <div className="flex flex-row space-x-2">
                  <IoCall className="w-6 h-6 text-secondary" />
                  <p className="text-neutralDarker">Telefon</p>
                </div>
                <Link hrefPrefix="tel" label="071 222 34 35" className="text-lg" />
              </div>
              <div className="flex flex-row justify-between w-full">
                <div className="flex flex-row space-x-2">
                  <IoPrint className="w-6 h-6 text-secondary" />
                  <p className="text-neutralDarker">Fax</p>
                </div>
                <p className="text-lg">071 222 40 15</p>
              </div>
              <div className="flex flex-row justify-between w-full">
                <div className="flex flex-row space-x-2">
                  <IoMail className="w-6 h-6 text-secondary" />
                  <p className="text-neutralDarker">Dr. med. Kluckert-Tomm</p>
                </div>
                <Link hrefPrefix="mailto" label="dr.ch.kluckert@hin.ch" className="text-lg" />
              </div>
              <div className="flex flex-row justify-between w-full">
                <div className="flex flex-row space-x-2">
                  <IoMail className="w-6 h-6 text-secondary" />
                  <p className="text-neutralDarker">Dr. med. Biener</p>
                </div>
                <Link hrefPrefix="mailto" label="andrea.biener@hin.ch" className="text-lg" />
              </div>
            </div>
            <div className="mt-8">
              <p className="text-neutralDarker">
                Bitte wenden Sie sich in dringenden Fällen oder ausserhalb der Öffnungszeiten an die Frauenklinik des
                Kantonsspitals St. Gallen:
              </p>
              <Link hrefPrefix="tel" label="071 494 69 69" />
            </div>
          </ContentSection>
        </div>
      </WaveBorderSection>
      <WaveFooter />
    </div>
  );
};

export default HomeScreen;
