import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import NavItem from '../../../types/NavItem';
import NavButton from './NavButton';

interface NavBarProps {
  navItems: NavItem[];
  className?: string;
}

const NavBar: React.FC<NavBarProps> = ({ navItems, className }) => {
  const [isScrolledDown, setIsScrolledDown] = useState(false);

  useEffect(() => {
    document.addEventListener('scroll', () => {
      setIsScrolledDown(window.scrollY > 140);
    });
  });

  return (
    <div
      className={clsx(
        'fixed z-10 left-0 right-0 top-0 pb-2 transition-all',
        { 'bg-primary pt-2 shadow-md': isScrolledDown },
        { 'pt-8': !isScrolledDown },
        className,
      )}
    >
      <div className="flex items-center justify-between mx-auto w-full max-w-2xl lg:max-w-5xl">
        <h3 className={clsx('transition-opacity', { 'opacity-100': isScrolledDown }, { 'opacity-0': !isScrolledDown })}>
          Gynteam St. Gallen
        </h3>
        <nav>
          <ul className="flex flex-row space-x-4">
            {navItems.map((item) => (
              <NavButton key={item.id} label={item.label} path={item.path} />
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default NavBar;
