import clsx from 'clsx';
import React from 'react';
import { useImage } from 'react-image';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  /** Either an image url or a list of image urls. If a list of image urls is provided, they are loaded one by one until an image could be loaded successfully */
  srcList: string | string[];
  /** How the image should be placed within the container */
  fillType?: 'contain' | 'cover';
};

const CustomImage: React.FC<Props> = ({ srcList, fillType = 'cover', className, ...props }) => {
  const { src, isLoading, error } = useImage({
    srcList: srcList,
    useSuspense: false,
  });

  const imgReady = !isLoading && !error;
  return (
    <div className={clsx('overflow-hidden', className)} {...props}>
      <div className="relative w-full h-full">
        <img
          className={clsx('w-full h-full transition-all duration-700', {
            'opacity-0': !imgReady,
            'opacity-100': imgReady,
            'object-cover': fillType === 'cover',
            'object-contain': fillType === 'contain',
          })}
          src={src}
        />
      </div>
    </div>
  );
};

export default CustomImage;
