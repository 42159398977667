import React from 'react';
import { useHistory } from 'react-router';
import WaveBorderSection from '../../components/container/WaveBorderSection';
import WaveFooter from '../../components/default/WaveFooter';
import WaveHeader from '../../components/default/WaveHeader';

const Impressum: React.FC = () => {
  const history = useHistory();

  return (
    <div>
      <WaveHeader pageName="Impressum" />
      <WaveBorderSection thisColor="neutralLighter" nextColor="neutralDarker" className="pb-24 space-y-20">
        {/* Text Content */}
        <p className="mb-4 text-neutralDarker">Verantwortlich für die Inhalte:</p>
        <p className="mb-8 text-black">
          Dr. med. Christiane Kluckert-Tomm
          <br />
          St. Leonhardstr. 20
          <br />
          9000 St. Gallen
          <br />
          Schweiz
        </p>
        <p className="mb-8 text-black">
          Kontakt:
          <br />
          <p className="text-primary cursor-pointer" onClick={() => history.push('/')}>
            www.kluckert.ch
          </p>
          <p className="text-primary cursor-pointer" onClick={() => window.open('mailto:dr.ch.kluckert@hin.ch')}>
            dr.ch.kluckert@hin.ch
          </p>
        </p>
        <p className="text-black">UID: CHE-___</p>
      </WaveBorderSection>
      <WaveFooter />
    </div>
  );
};

export default Impressum;
